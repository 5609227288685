/**
 * BlogAuthorOverview Page
 * @namespace BlogAuthorOverviewPage
 */
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    checkInput,
    deleteUser,
    getRoles,
    getUserRoles,
    getUsers,
    resetRoleForUser,
    setAcceptUser,
    setRoleForUser
} from "./userManagementUtil"
import userStore from "./userStore"
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import history from "../../helper/browserHistory";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

function deleteUserClicked(user, setUsers) {
    deleteUser(user.id).then(res => {
        getUsers().then(users => {
            setUsers(users);
        })
    })
}

function addUser(user, setUsers) {
    if (checkInput(user)) {
        userStore.registerUser(user).then(res => {
            getUsers().then(users => {
                setUsers(users);
            })
        })
    } else {
        alert("Alle Felder müssen befüllt werden und das Passwort muss übereinstimmen");
    }
}

function userClicked() {
//TODO: ?
}

function roleChecked(user, role, setUserRoles) {
    setRoleForUser(user.id, role.id).then(res => {
        getUserRoles().then(userRoles => {
            setUserRoles(userRoles);
        })
    });
}


function roleUnchecked(user, role, setUserRoles) {
    resetRoleForUser(user.id, role.id).then(res => {
        getUserRoles().then(userRoles => {
            setUserRoles(userRoles);
        })
    });
}

function changeAcceptUser(user, setUser) {
    setAcceptUser(user.id, !user.accepted).then(res => {
        setUser(prevState => ({...prevState, accepted: res}));
    });
}

function handleInputChange(name, e, setUser) {
    let value = e.target.value
    setUser(prevState => ({...prevState, [name]: value}));
}
function showRows(users,roles,userRoles,setUsers,setUser,setUserRoles,i18n){
    return users.map(user => {
        let roleRow = roles.map(role => userRoles.findIndex(userRole => (userRole.roleId === role.id && userRole.userId === user.id)) !== -1 ?
            <Table.Cell><Checkbox checked={true}
                                  onChange={() => roleUnchecked(user, role, setUserRoles)}/></Table.Cell>
            : <Table.Cell><Checkbox checked={false}
                                    onChange={() => roleChecked(user, role, setUserRoles)}/></Table.Cell>);
        return (<Table.Row key={user.id}>
                <Table.Cell onClick={() => userClicked()}>{user.username}</Table.Cell>
                <Table.Cell onClick={() => userClicked()}>{user.firstName}</Table.Cell>
                <Table.Cell onClick={() => userClicked()}>{user.lastName}</Table.Cell>
                <Table.Cell onClick={() => userClicked()}>{user.email}</Table.Cell>
                <Table.Cell>
                    <Checkbox checked={user.accepted} onChange={() => {
                        changeAcceptUser(user, setUser)
                        user.accepted = !user.accepted;
                    }}/>
                </Table.Cell>
                {roleRow}
                <Table.Cell>
                    <Button disabled={userStore.userFromServer.id === user.id} color={'red'}
                            onClick={() => deleteUserClicked(user, setUsers)}>
                        {i18n.t('login:user.delete')}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    }
    );
}


const UserManagementPage = (props) => {
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordRepeat: "",
        admin: false,
        accepted: false,
        roles: [],
        rolesEdit: false
    });

    //ComponentDidMount
    useEffect(() => {
        if (!userStore.isAdmin) {
            history.push("/");
        } else {
            window.scrollTo(0, 0);
            getUsers().then(users => {
                setUsers(users);
            });
            getRoles().then(roles => {
                setRoles(roles);
            });
            getUserRoles().then(userRoles => {
                setUserRoles(userRoles);
            });
        }

    }, [])

    const {i18n} = props;
    let userRolesHeaders = roles.map(role =>
        <Table.HeaderCell>{role.title}</Table.HeaderCell>
    );

    return (
        [
            <div style={{"width": "90%", "margin": "auto", paddingTop: '300px'}}>
                <Button color={"green"} onClick={() => setIsUserModalOpen(true)}>
                    {i18n.t("login:user.add")}
                </Button>
                {/* <Button onClick={this.newArticle.bind(this)}>{i18n.t("blog.new_article")}</Button> */}
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{i18n.t("login:user.username")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("login:user.first_name")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("login:user.last_name")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("login:user.email")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("login:user.accepted")}</Table.HeaderCell>
                            {userRolesHeaders}
                            <Table.HeaderCell>{i18n.t("login:user.delete")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {showRows(users,roles,userRoles,setUsers,setUser,setUserRoles,i18n)}
                    </Table.Body>
                </Table>
                {/*}   <Modal open={this.state.deleteModal} basic size='small'>
                    <Header icon='trash alternate' content={i18n.t("blog.delete_article")} />
                    <Modal.Content>
                        {i18n.t("blog.really_delete")}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='red' inverted onClick={this.closeClicked.bind(this)}>
                            <Icon name='remove'  /> {i18n.t("blog.cancel")}
                        </Button>
                        <Button color='green' inverted onClick={this.deleteAcceptedClicked.bind(this)}>
                            <Icon name='checkmark' /> {i18n.t("blog.ok")}
                        </Button>
                    </Modal.Actions>
                </Modal> */}
            </div>,
            <Modal open={isUserModalOpen} onClose={() => setIsUserModalOpen(false)}>
                <Modal.Header>{i18n.t("login:user.add")}</Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        <Form.Field>
                            <label>{i18n.t("login:user.first_name")}</label>
                            <Form.Input fluid value={users.firstName}
                                        onChange={e => handleInputChange('firstName', e, setUser)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{i18n.t("login:user.last_name")}</label>
                            <Form.Input fluid value={users.lastName}
                                        onChange={e => handleInputChange('lastName', e, setUser)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{i18n.t("login:user.username")}</label>
                            <Form.Input fluid value={users.username}
                                        onChange={e => handleInputChange('username', e, setUser)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{i18n.t("login:user.email")}</label>
                            <Form.Input fluid value={users.email}
                                        type='email'
                                        onChange={e => handleInputChange('email', e, setUser)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{i18n.t("login:user.password")}</label>
                            <Form.Input fluid value={users.password}
                                        type='password'
                                        onChange={e => handleInputChange('password', e, setUser)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>{i18n.t("login:user.confirm_password")}</label>
                            <Form.Input fluid value={users.passwordRepeat}
                                        type='password'
                                        onChange={e => handleInputChange('passwordRepeat', e, setUser)}/>
                        </Form.Field>
                    </Form>
                    <div style={{marginTop: "10px"}}>
                        <Button color={"green"} onClick={() => {
                            addUser(user, setUsers);
                            setIsUserModalOpen(false);
                        }}>{i18n.t("common:common.save")}</Button>
                        <Button color={"red"} onClick={() => {
                            setIsUserModalOpen(false);
                        }}>{i18n.t("common:common.cancel")}</Button>
                    </div>
                </Modal.Content>
            </Modal>
        ]
    )
}

export default withTranslation(['login', 'common'])(UserManagementPage)
